import { Formik, Form} from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import Spinner from '../../../Components/Loadig';
import Select from 'react-select';
import Cobranza from '../../../Services/Cobranza/Cobranza';
import CobranzaService from '../../../Services/Cobranza/Cobranza';
import { SelectField } from '../../../Components/SelectField';

const AgregarCobradorModal = ({ isOpen, setIsOpen, setAction, nivelDefault }) => {
    const refbtn = useRef();
    const [dataPersonal, setDataPersonal] = useState([]);
    const userName = sessionStorage.getItem('usuario')
    const userId = sessionStorage.getItem('userId').trim()

    let initial = {
        nombrecobrador: "",
        nivelcobranza: nivelDefault?.value,
        telefono: "",
        userid:''
    }

    useEffect(()=>{
        handleGetAllUsersNotInCobranza();
        console.log(nivelDefault);
    },[]);

    const handleGetAllUsersNotInCobranza = async () => {
        let NewData = [];
        await Cobranza.getAllUsersNotInCobranza()
            .then(res => {
                if(res.data.callSucceded){
                    res.data.responseData.map(item => {
                        NewData.push({value: item.userId, label: item.names})
                    })
                }
            })
            .catch(e => {
                console.log(`ERROR -- ${e}`)
            })
        setDataPersonal(NewData);
    }

    const dataNiveles = [
        { value: "", label: "Todos los niveles"},
        { value: "1", label: "Nivel 1"},
        { value: "2", label: "Nivel 2"},
        { value: "3", label: "Nivel 3"},
        { value: "4", label: "Nivel 4"},
    ]

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    const validate = Yup.object({
        nombrecobrador: Yup.string()
            .required("El campo es requerido."),
        nivelcobranza: Yup.string()
            .required("El campo es requerido."),
        telefono: Yup.string()
            .required("El campo es requerido.")
    });

    const handleAdd = async (values) => {
        setLoading(true);
        let data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "flag": true,
            "anyObject": {
                "userid": values.userid,
                "nivel_cobrador": values.nivelcobranza.toString(),
                "zona":"A",
                "activo":1,
                "numTelefono":values.telefono
            }
        }

        console.log(data)

        await CobranzaService.createEjecutivoCobranza(data)
            .then(res => {
                console.log(res)
                setIsOpen(false);
                setLoading(false);
                setAction("update");
            })
            .catch(e => {
                setLoading(false);
                console.log(`ERROR -- ${e}`)
            })
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAdd(values);
            }}
        >{
            ({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-2 mx-4' style={{ width: '35rem', height: '22rem' }}>
                            <Form>
                                <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Agregar cobrador </strong></span>
                                <div className="mb-3 row" style={{marginTop:30}}>
                                    <div className="col-sm-6">
                                        <label className='col-12 mx-1'>
                                            <SelectField items={dataPersonal} label="Nombre" name='nombrecobrador' onChange={(e) => { 
                                                setFieldValue("nombrecobrador", e.value === "" ? "" : e.label ); 
                                                setFieldValue("userid", e.value === "" ? "" : e.value ); 
                                                }} />
                                        </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <TextField label="Usuario sistema" name="usuariosistema" type="text" holder={userName} disabled={true} />
                                    </div>
                                </div>
                                <div className="mb-3 row" style={{marginTop:30}}>
                                    <div className="col-sm-6">
                                        <label className='col-12 mx-1'>
                                            Nivel de cobranza
                                            <Select name="nivelcobranza" defaultValue={nivelDefault} isDisabled={true} onChange={(e) => {
                                                // setFieldValue("nivelcobranza", e.value != 0 ? e.value : null); 
                                                }} 
                                                placeholder="Seleccionar" />
                                        </label>
                                    </div>
                                    <div className="col-sm-6">
                                    <TextField label="Número de teléfono" min={0} name="telefono" value={values.telefono} type="text" holder="Escribir teléfono"
                                        onChange={(event) => {setFieldValue("telefono", event.target.value);}} />
                                    </div>
                                </div>
                                <section className='d-flex justify-content-end mt-5'>
                                    <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' ref={refbtn} type="submit" disabled={!(isValid)}>Guardar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
        }
    </Formik>
    )
}

export default AgregarCobradorModal