import React, { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast'
import useHistorialVisitasDetalle from "../../../Hooks/SeguimientoVisita/HistorialVisitasDetalle"
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard"
import ContratoInfoCard from "../../../Components/ContratoInfoCard"
import CarteraInfoCard from "../../../Components/CarteraInfoCard"
import Accordion from "../../../Components/TitleAcordeon"
import Spinner from "../../../Components/Loadig"
import Modal from "../../../Components/Modal/Modal"
import { TextField } from '../../../Components/TextField';
import { Formik, Form } from 'formik'
import { TextAreaField } from '../../../Components/TextAreaField';


const HistorialVisitasDetalle = () => {
    const refFormik = useRef();
    const { state } = useLocation();
    const {
        handleReturnPage,
        currentDataSeguimiento,
        userDetail,
        promesasRotas,
        loading
    } = useHistorialVisitasDetalle(state);

    let initialVisitaDomicilia = {
        numeroVisita: "06",
        encontroCliente: "Si",
        comentarios: "Se realizo la visita correctamente",
        motivoIngreso: "Morosidad",
        comentariosIngresoCartera: "No aplica",
        registroConcluido: true,
        fechaInicioProceso: "23/10/2023 09:00",
        fechaFinProceso: "23/10/2023 09:00"
    };
    const [datosVisitaDomicilia, setDatosVisitaDomicilia] = useState(initialVisitaDomicilia);

    let initialRegistroPromesa = {
        tipoPromesa: "Promesa de pago",
        motivoPromesa: "Motivo",
        fechaCompromiso: "11/10/2024",
        horarioCompromiso: "10:30AM",
        montoPrometido: "$4,500.00",
        montoVencido: "$25,000.00",
        comentariosPromesaPago: "El cliente prometio el monto de $4,500.00",
        registroConcluido: true,
        fechaInicioProceso: "25/10/2023 09:00",
        fechaFinProceso: "25/10/2023 09:00",
    };
    const [datosRegistroPromesa, setDatosRegistroPromesa] = useState(initialRegistroPromesa);

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
                <div class="row">
                    <header className='heder-subtitle d-flex justify-content-between align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                        <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                            <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                                <i className="bx bxs-chevron-left mx-2" ></i>
                                Regresar a listado de historial de visitas
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 my-2 text-end">
                            <button className="btn mx-2" style={{ background: "#F5F5F5", color: "Black" }} disabled>
                                <i className="glyphicon fa fa-eye" style={{ color: "black" }}></i>Modo lectura
                            </button>
                        </div>
                    </header>
                </div>

                <div className="col col-11 d-flex">
                    {/* vehiculo */}
                    <div className="col col-6 px-4 py-2">
                        <VehiculoInfoCard
                            // VIN={currentDataSeguimiento.VIN}
                            VIN={"3VW1K1AJ5HM203824"}
                            verClaves={true}
                        />
                    </div>
                    {/* contrato */}
                    <div className="col col-6 px-4 py-2">
                        <ContratoInfoCard
                            contrato={currentDataSeguimiento} />
                    </div>
                </div>
                {/* informacion de cartera */}
                <div className="col col-11 px-4 py-2">
                    <CarteraInfoCard
                        contrato={currentDataSeguimiento}
                        user={userDetail}
                        promesasRotas={promesasRotas} />
                </div>
                {/* visita domiciliaria */}
                <div>
                    <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                        <Accordion title="Visita Domiciliaria">
                            <p className="text-base"><strong>Detalle de visita</strong></p>
                            <Formik innerRef={refFormik} enableReinitialize={true} initialValues={datosVisitaDomicilia}>
                                {({ values }) => (
                                    <Form>
                                        <div className='my-2 mt-4'>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField disabled label="Número de visita" name="numeroVisita" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField disabled label="¿Encontraste al cliente?" name="encontroCliente" type="text" holder="" />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                    <TextAreaField disabled label="Comentarios" className="form-control col-12" rows="4" name="comentarios" type="text" holder="Escribe" />
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-base"><strong>Detalle de visita</strong></p>
                                        <div className='my-2 mt-4'>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField disabled label="Motivo de ingreso" name="motivoIngreso" type="text" holder="" />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                    <TextAreaField disabled label="Comentarios de ingreso a la cartera" className="form-control col-12" rows="4" name="comentariosIngresoCartera" type="text" holder="" />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <input className="form-check-input" type="checkbox" disabled checked={values.registroConcluido} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                    <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik >
                        </Accordion>
                    </div>
                </div>
                {/* registro de promesa */}
                <div>
                    <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                        <Accordion title="Promesa de pago">
                            <p className="text-base"><strong>Detalle de promesa de pago</strong></p>
                            <Formik innerRef={refFormik} enableReinitialize={true} initialValues={datosRegistroPromesa}>
                                {({ values }) => (
                                    <Form>
                                        <div className='my-2 mt-4'>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField disabled label="Tipo de promesa" name="tipoPromesa" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField disabled label="Motivo promesa" name="motivoPromesa" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField disabled label="Fecha Compromiso" name="fechaCompromiso" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField disabled label="Horario Compromiso" name="horarioCompromiso" type="text" holder="" />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField disabled label="Monto prometido" name="montoPrometido" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <label className="form-check-label">Monto Vencido</label><br></br>
                                                    <label className="form-check-label">{values.montoVencido}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-2 mt-4'>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                    <TextAreaField disabled label="Comentarios promesa de pago" className="form-control col-12" rows="4" name="comentariosPromesaPago" type="text" holder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <input className="form-check-input" type="checkbox" disabled checked={values.registroConcluido} />
                                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik >
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HistorialVisitasDetalle