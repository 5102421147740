import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import ErrorModal from "../../../../Components/Modal/ErrorModal";
import SuccessModal from "../../../../Components/Modal/SuccessModal";
import { SelectField } from "../../../../Components/SelectField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import { TextField } from "../../../../Components/TextField";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import { message } from "../../../../Utils/functions";
import { useDispatch, useSelector } from "react-redux";
import SubirDocumentoModal from "./SubirDocumentoModal";
import dataDocsServices from "../../../../Services/DataDocs/dataDocsServices";
import AuthLogin from "../../../../Utils/AuthLogin";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { setVisitaDomiciliariaId } from "../../../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice";
import VisorFile from "../../../../Components/VisorFile";

const auth = new AuthLogin();
const VisitaDomiciliariaCard = React.forwardRef(({  ...props}, ref) => {
  const {contratoActual, itemGeneral} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
  const [collpsableGrua, setCollpsableGrua] = useState(false);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(0);
  const [dataMotivoIngreso, setDataMotivoIngreso]=useState([])
  const [fechaInicioProceso, setFechaInicioProceso] = useState("23/10/2023 09:00")
  const [fechaFinProceso, setFechaFinProceso] = useState("23/10/2023 09:00")
  const [openModalSubirDocumento, setOpenModalSubirDocumento] = useState(false)
  let url = auth.UrlDataDocs();
  const [fileName, setFileName] = useState("");
  const [SelectedDocs, setSelectedDocs] = useState([]);
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const navigate = useNavigate();
  const [registradoPreviamente, setRegistradoPreviamente] = useState(false);
  const [confirmarOperacion, setConfirmarOperacion] = useState("")
  const [archivoId, setArchivoId] = useState("")
  const [infoVisitaDom, setInfoVisitaDom] = useState({})
  const [nombreDocumento, setNombreDocumento] = useState("")
  const dispatch = useDispatch();
  const [archivosCargados, setArchivosCargados] = useState([])
  const [archivoSeleccionado, setArchivoSeleccionado] = useState({})

  let initial = {
    num_visita: contratoActual.visitas,
    entregaste_cliente: '',
    resultado: '',
    encontraste_documento: '',
    tipo_documento: '',
    motivo: '',
    especificacion: '',
    motivo_ingreso: '',
    comentarios: '',
    comentario_ingreso: '',
    reg_concluido: false
  };

  const [initialValue, setInitialValue] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    isOpen: false,
    type: 1,
    title: "",
    message: "",
    data: [],
    authorizationType: 2,
    Origin: "Listado",
  });

  const GetAllMotivosIngreso = async () => {
    let NewData =[]
    setLoading(true);
    await CobranzaService.getAllMotivoIngresoCartera()
      .then(res=>{
        res.data.responseData.map(item=>{
          NewData.push({
            value: item.id,
            label: item.nombre
          })
        })
        setLoading(false)
      })
      .catch(e=>{
        setLoading(false)
      })
      setDataMotivoIngreso(NewData);
  }

  useEffect(()=>{
    console.log(itemGeneral)
    console.log(contratoActual)
    GetAllMotivosIngreso()
    GetAllVisitasDomiciliarias()
  },[])

  useEffect(()=>{
    if(confirmarOperacion !== ""){
      CreateRelacionVisitaDocumento()
      setConfirmarOperacion("")
    }
  },[confirmarOperacion])

  const dataOpcionSiNo = [
    { value: 1, label: "Si" },
    { value: 2, label: "No" }
  ];

  const dataTipoDocumento = [
    { value: 1, label: "Documento uno" },
    { value: 1, label: "Documento dos" },
  ]

  const validate = Yup.object({
    entregaste_cliente: Yup.string().required("El campo es requerido."),
      resultado: Yup.string().required("El campo es requerido."),
      encontraste_documento: Yup.string().required("El campo es requerido."),
      tipo_documento: Yup.string().required("El campo es requerido."),
      motivo: Yup.string().required("El campo es requerido."),
      especificacion: Yup.string().required("El campo es requerido."),
      motivo_ingreso: Yup.string().required("El campo es requerido."),
  });

  const handleGuardar = async(values, actions) =>{
    setLoading(true);

    let data ={
      "verb": "string",
      "id": "string",
      "field": "string",
      "value": "string",
      "flag": false,
      "anyObject": {
        "id":crypto.randomUUID(),
        "ruta_head_id":itemGeneral.ruta_head_id,
        "ruta_item_id":itemGeneral.id,
        "evento_item_id": itemGeneral.evento_item_id,
        "evento_head_id": itemGeneral.evento_head_id,
        "num_visita": null,
        "entregaste_cliente": values.entregaste_cliente,
        "resultado": values.resultado,
        "encontraste_documento": values.encontraste_documento,
        "tipo_documento": values.tipo_documento,
        "motivo":values.motivo,
        "especificacion":values.especificacion,
        "comentarios":values.comentarios,
        "motivo_ingreso": values.motivo_ingreso,
        "comentario_ingreso":values.comentario_ingreso,
        "registro_concluido": values.reg_concluido === true ? 1 : 0
      }
    }

    await CobranzaService.CreateVisitaDomiciliaria(data)
      .then(res => {
        setLoading(false)
        props.handleNextSteps(true);
        toast.success(message("¡Operación exitosa!", res.data.mensaje));
        GetAllVisitasDomiciliarias()
        // actions.resetForm();
      }).catch(e => {
        setLoading(false)
        props.handleNextSteps(false);
        if (e.response !== undefined)
          toast.error(message("¡Error!", e.response.data.message));
        else
          toast.error(message("¡Error!", e.message));
      })
  }

  const GetAllVisitasDomiciliarias = async() =>{
    let NewData = []
    setLoading(true)
    await CobranzaService.GetAllVisitasDomiciliarias()
      .then(res =>{
        res.data.responseData?.map(item=>{
          if(item.ruta_item_id === itemGeneral.id){
            if(item.visitaDomDocs !== null){
              item.visitaDomDocs?.map(file=>{
                if(file.binario != null){
                  NewData.push({
                    value: file.binario.documentoId,
                    label: file.binario.nombreDocumento,
                    idFolder: file.binario.idFolder
                  })
                }
              })
            }
            dispatch(setVisitaDomiciliariaId(item.id))
            setInfoVisitaDom(item);
            setRegistradoPreviamente(true)
            setOpcionSeleccionada(item.entregaste_cliente === 0 ? 2 : 1)
            setInitialValue({
              num_visita: contratoActual.visitas,
              entregaste_cliente: item.entregaste_cliente === 0 ? "No" : "Si",
              resultado: item.resultado,
              encontraste_documento: item.encontraste_documento === 0 ? "No" : "Si",
              tipo_documento: item.tipo_documento,
              motivo: item.motivo,
              especificacion: item.especificacion,
              motivo_ingreso: item.motivo_ingreso,
              comentarios: item.comentarios,
              comentario_ingreso: item.comentario_ingreso,
              reg_concluido: item.reg_concluido === 1 ? true : false
            });
            props.handleNextSteps(true);
          }
        })
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        props.handleNextSteps(false);
        if (e.response !== undefined)
          toast.error(message("¡Error!", e.response.data.message));
        else
          toast.error(message("¡Error!", e.message));
      })
      setArchivosCargados(NewData)
  } 

  const CreateRelacionVisitaDocumento = async()=>{
    const data = {
      "verb": "string",
      "id": "string",
      "field": "string",
      "value": "string",
      "level": 0,
      "flag": true,
      "anyObject": {
        "id":archivoId,
        "visita_dom_id":infoVisitaDom.id,
        "tipo_doc":"documento",
        "extension_doc":".pdf",
        "nombre_doc":nombreDocumento,
        "descripcion":"",
        "url":"",
        "subido":1,
        }
    }

    await CobranzaService.CreateRelacionRutaDocumento(data)
      .then(res=>{
        toast.success(message("Documento relacionado correctamente", res.data.mensaje));
      })
      .catch(e => {
        setLoading(false)
        if (e.response !== undefined)
          toast.error(message("¡Error!", e.response.data.message));
        else
          toast.error(message("¡Error!", e.message));
      })
  }

  const dataResultados = [
    { value: 1, label: "No dan acceso" },
    { value: 2, label: "Carta visita" },
    { value: 3, label: "Carta juridica" },
    {
      value: 4,
      label: "Cita despacho esterno",
    },
    { value: 5, label: "Promesa de pago" },
    {
      value: 6,
      label: "Promesa de auto garantía",
    },
    {
      value: 7,
      label: "Promesa de auto defenitiva",
    },
  ]

  const dataMotivoNoEncontrado = [
    { value: 1, label: "Ilocalizable" },
    { value: 2, label: "Contacto vía tercero*"}
  ]

  const dataEspecificacionDetalle = [
    { value: 1, label: "No vive ahí" },
    { value: 2, label: "Casa abandonada" },
    { value: 3, label: "Casa en venta" },
    { value: 4, label: "No existe domicilio" },
    { value: 5, label: "No trabaja ahí" },
    { value: 6, label: "No exite negocio" },
  ]

  const handleGetTokenDatadocs = async () => {
    await dataDocsServices.UpdateTokenDataDc()
      .then(res => {
        auth.handleAuthSesionDataDocs(res.data.data);
      }).catch(e => { })
  }
  const handleViewdocs = async () => {
    setLoading(true);
    setFileName(archivoSeleccionado.label);
    await handleGetTokenDatadocs();
    await axios.post(`${url}Documento/GetFileById/${archivoSeleccionado.idFolder}/${archivoSeleccionado.value}/false`, {}, {
        headers: {
            'Authorization': 'Bearer ' + auth.tokenDataDocs(),
        },
        responseType: "blob"
    })
    .then(res => {
        var file = new File([res.data], res.data.name, { type: res.data.type });
        setSelectedDocs([file])
        setLoading(false);
        setIsOpenDocs(true)
    }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
            toast.error(message("Error.!", e.response.data.errors?.error));
        else
            toast.error(message("Error.!", e.message));
    })
  }

  const handleVisualizarDocumentos = async()=>{
    await handleViewdocs()
  }

  const handleUploadFile = async () => {

    if(itemGeneral.visitaDoms[0]?.visitaDomDocs != null){
      setArchivoId(itemGeneral.visitaDoms[0].visitaDomDocs[0].id)
      navigate(`/cobranza/seguimiento-visita/datadocs/fCobranza/eVisitaD/${contratoActual?.contrato_id}/${itemGeneral.visitaDoms[0].visitaDomDocs[0].id}`, {
        state: { ...contratoActual, registroId: archivoId, vin: contratoActual.vin, nombreMarca: "", nombreModelo: "", nombreVersion: "", nombreYear: "" }
      });
    }
    else{
      const archivoId = crypto.randomUUID();
      setArchivoId(archivoId)
      navigate(`/cobranza/seguimiento-visita/datadocs/fCobranza/eVisitaD/${contratoActual?.contrato_id}/${archivoId}`, {
        state: { ...contratoActual, registroId: archivoId, vin: contratoActual.vin, nombreMarca: "", nombreModelo: "", nombreVersion: "", nombreYear: "" }
      });
    }

      
  }

  const handleSubirDocumento = () =>{
    const archivoId = crypto.randomUUID();
    setArchivoId(archivoId)
    setOpenModalSubirDocumento(true)
  }

  return (
    <>
      <Modal isOpen={loading} color="transparent"> <Spinner message={""} /> </Modal>
      <Modal isOpen={modalMessage.isOpen} color="#fff" width={660}>
        {modalMessage.type === 1 && (
          <ErrorModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        { modalMessage.type === 2 && ( <SuccessModal modalMessage={modalMessage} setModalMessage={setModalMessage} /> )}
      </Modal>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "#47a066",
              color: "#FFFF",
              borderLeft: "10px solid #2f7246",
            },
          },
          error: {
            style: {
              background: "#d53f3f",
              color: "#FFFF",
              borderLeft: "10px solid #ac241a",
            },
          },
        }}
      />
      <div className="row wrapper-vehiculo">
        <div className="col col-12">
          <div className="row mx-md-4 my-4">
            <div className="col-12 px-md-3">
              <div className="p-4 white-wrapper mb-2">
                <div
                  className="row mt-2 px-4"
                  data-bs-toggle="collapse"
                  href="#collapseVisitaDomiciliaria"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseRegistroTramite"
                  onClick={() => setCollpsableGrua(!collpsableGrua)}
                >
                  <div className="col-11">
                    <h6>
                      <span className={`semaforo semaforo-${initialValue.color}`} ></span>{" "}
                      <strong>Visita Domiciliaria</strong>
                    </h6>
                  </div>
                  <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                    <i className={ !collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2" }></i>
                  </div>
                </div>
                <div className="collapse" id="collapseVisitaDomiciliaria">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={validate}
                    initialValues={initialValue}
                    onSubmit={(values, actions) => {
                      handleGuardar(values, actions);
                  }}>
                    {({ isValid, values, setFieldValue, errors, actions }) => (
                      <>
                        <Form>
                          <div className="separator"></div>
                          <div className="row mt-2 px-4">
                            <div className="col-2 mt-4">
                              <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Detalle de visita</strong></span>
                              </p>
                            </div>
                            <div className="col-10 row justify-content-end">
                              {registradoPreviamente &&(
                              <div className="col-12 col-lg-3">
                                <div className="col-auto">
                                  <button
                                    id="btnVisualizarDocumentos"
                                    className="btn btn-outline col-12 mw-100 d-flex align-items-center justify-content-center"
                                    type="button"
                                    onClick={handleVisualizarDocumentos}
                                    style={{
                                      padding: "10px",
                                      lineHeight: "20px",
                                      border: "none",
                                      backgroundColor: "#f0f0f0",
                                  }}>
                                    <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                    Visualizar Documentos
                                  </button>
                                </div>
                              </div>
                              )}
                              {registradoPreviamente &&(
                              <div className="col-12 col-lg-2">
                                <SelectField
                                  id="archivoscargados"
                                  label="Archivos cargados"
                                  name="archivoscargados"
                                  items={archivosCargados}
                                  onChange={(event) => {
                                    setArchivoSeleccionado(event)
                                }}/>
                              </div>
                              )}
                              {registradoPreviamente &&(
                              <div className="col-12 col-lg-2">
                                <div className="col-auto">
                                  <button
                                    id="btnSubirDocumento"
                                    className="btn btn-outline col-12 mw-100 d-flex align-items-center justify-content-center"
                                    type="button"
                                    onClick={ handleUploadFile }
                                    style={{
                                      padding: "10px",
                                      lineHeight: "20px",
                                      border: "none",
                                      backgroundColor: "#f0f0f0",
                                  }}>
                                    <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                    Subir documento
                                  </button>
                                </div>
                              </div>
                              )}
                              {registradoPreviamente &&(
                              <div className="col-12 col-lg-2">
                                <div className="col-auto">
                                  <button
                                    id="btnSubirDocumento"
                                    className="btn btn-outline col-12 mw-100 d-flex align-items-center justify-content-center"
                                    type="button"
                                    onClick={ handleSubirDocumento }
                                    style={{
                                      padding: "10px",
                                      lineHeight: "20px",
                                      border: "none",
                                      backgroundColor: "#f0f0f0",
                                  }}>
                                    <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                    Tomar foto
                                  </button>
                                </div>
                              </div>
                              )}
                              {
                                !registradoPreviamente &&(
                                  <div className="col-12 col-lg-2">
                                    <div className="col-auto">
                                      <button
                                        className="btn col-10"
                                        type="submit"
                                        disabled={!(isValid) || registradoPreviamente}
                                        style={{
                                          padding: "10px",
                                          lineHeight: "25px",
                                      }}>
                                        Guardar
                                      </button>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                          <div className="mb-3 row px-4" >
                            <div className="col-12 col-lg-2">
                              <TextField label="Número de Visita" name="num_visita" type="text" holder={0} disabled={true} />
                            </div>
                            <div className="col-12 col-lg-2">
                              {
                                !registradoPreviamente
                                ?
                                <SelectField
                                  id="entregaste_cliente"
                                  label="¿Encontarste al Cliente?"
                                  name="entregaste_cliente"
                                  items={dataOpcionSiNo}
                                  onChange={(event) => {
                                    if(event.value === 1){
                                      setFieldValue("entregaste_cliente", 1);
                                      setOpcionSeleccionada(1);
                                      setFieldValue("motivo", 'NA');
                                      setFieldValue("especificacion", 'NA');
                                      setFieldValue("resultado", '');
                                    }
                                    else if(event.value === 2){
                                      setFieldValue("entregaste_cliente", 0);
                                      setOpcionSeleccionada(2);
                                      setFieldValue("resultado", 'NA');
                                      setFieldValue("motivo", '');
                                      setFieldValue("especificacion", '');
                                    }
                                    else {
                                      setFieldValue("entregaste_cliente", "");
                                      setOpcionSeleccionada(0);
                                      setFieldValue("motivo", '');
                                      setFieldValue("especificacion", '');
                                      setFieldValue("resultado", '');
                                    }
                                      setFieldValue("encontraste_documento", "");
                                      setFieldValue("tipo_documento", "");
                                  }}
                                />
                                : <TextField label="¿Encontarste al Cliente?" name="entregaste_cliente"  type="text" holder={values.entregaste_cliente}  disabled={true} />
                              }
                            </div>
                            {
                              opcionSeleccionada === 1 && (
                              <>
                                <div className="col-12 col-lg-2">
                                {
                                  !registradoPreviamente
                                  ? <SelectField
                                      id="resultado"
                                      label="Resultado"
                                      name="resultado"
                                      items={dataResultados}
                                      onChange={(event) => {
                                        setFieldValue("resultado", event.value === "" ? "" : event.label);
                                    }}/>
                                  : <TextField label="Resultado" name="resultado"  type="text" holder={values.resultado}  disabled={true} />
                                }
                                </div>
                                <div className="col-12 col-lg-2">
                                  {
                                    !registradoPreviamente
                                    ? <SelectField
                                        id="encontraste_documento"
                                        label="¿Entregaste documentos?"
                                        name="encontraste_documento"
                                        items={dataOpcionSiNo}
                                        onChange={(event) => {
                                          setFieldValue("encontraste_documento", event.value === "" ? "" : event.value === 1 ? event.value : 0 );
                                          if(event.value === 1){
                                            setFieldValue("tipo_documento", "");
                                          }
                                          else if(event.value === ""){
                                            setFieldValue("tipo_documento", "");
                                          }
                                          else{
                                            setFieldValue("tipo_documento", "NA");
                                          }
                                      }}/>
                                    : <TextField label="¿Entregaste documentos?" name="encontraste_documento"  type="text" holder={values.encontraste_documento}  disabled={true} />
                                  }
                                </div>
                                <div className="col-12 col-lg-3">
                                  {
                                    !registradoPreviamente
                                    ? <SelectField
                                        id="tipo_documento"
                                        label="Tipo de documento"
                                        name="tipo_documento"
                                        disabled={values.encontraste_documento !== 1 }
                                        items={dataTipoDocumento}
                                        onChange={(event) => {
                                          setFieldValue("tipo_documento", event.value === "" ? "" : event.label);
                                      }}/>
                                    : <TextField label="Tipo de documento" name="tipo_documento"  type="text" holder={values.tipo_documento}  disabled={true} />
                                  }
                                </div>
                              </>
                            )}
                            {
                              opcionSeleccionada === 2 && (
                              <>
                                <div className="col-12 col-lg-2">
                                  {
                                    !registradoPreviamente
                                    ? <SelectField
                                        id="motivo"
                                        label="Motivo"
                                        name="motivo"
                                        items={dataMotivoNoEncontrado}
                                        onChange={(event) => {
                                          setFieldValue("motivo", event.value === "" ? "" : event.label);
                                      }}/>
                                    : <TextField label="Motivo" name="motivo"  type="text" holder={values.motivo}  disabled={true} />
                                  }
                                </div>
                                <div className="col-12 col-lg-2">
                                  {
                                    !registradoPreviamente
                                    ? <SelectField
                                        id="especificacion"
                                        label="Especificación"
                                        name="especificacion"
                                        items={dataEspecificacionDetalle}
                                        onChange={(event) => {
                                          setFieldValue("especificacion", event.value === "" ? "" : event.label);
                                      }}/>
                                    :<TextField label="Especificación" name="especificacion"  type="text" holder={values.especificacion}  disabled={true} />
                                  }
                                </div>
                              </>
                            )}
                          </div>
                          {
                            opcionSeleccionada === 2 && (
                            <>
                              <div className="mb-3 row px-4" >
                                <div className="col-12 col-lg-2">
                                  {
                                    !registradoPreviamente
                                    ?<SelectField
                                      id="encontraste_documento"
                                      label="¿Entregaste Documentos?"
                                      name="encontraste_documento"
                                      items={dataOpcionSiNo}
                                      onChange={(event) => {
                                        setFieldValue("encontraste_documento", event.value === "" ? "" : event.value === 1 ? event.value : 0 );
                                        if(event.value === 1){
                                          setFieldValue("tipo_documento", "");
                                        }
                                        else if(event.value === ""){
                                          setFieldValue("tipo_documento", "");
                                        }
                                        else{
                                          setFieldValue("tipo_documento", "NA");
                                        }
                                    }}/>
                                    :<TextField label="¿Entregaste Documentos?" name="encontraste_documento"  type="text" holder={values.encontraste_documento}  disabled={true} />
                                  }
                                </div>
                                <div className="col-12 col-lg-3">
                                  {
                                    !registradoPreviamente
                                    ? <SelectField
                                        id="tipo_documento"
                                        label="Tipo de documento"
                                        name="tipo_documento"
                                        disabled={values.encontraste_documento !== 1 }
                                        items={dataTipoDocumento}
                                        onChange={(event) => {
                                          setFieldValue("tipo_documento", event.value === "" ? "" : event.label);
                                      }}/>
                                    : <TextField label="Tipo de documento" name="tipo_documento"  type="text" holder={values.tipo_documento}  disabled={true} />
                                  }
                                </div>
                              </div>
                            </>
                          )}
                          <div className="mb-3 row px-4" style={{ marginTop: 30 }}>
                          <div className="col-10">
                            <TextAreaField
                              label="Comentarios:"
                              className="col-8 mt-2"
                              rows="4"
                              name="comentarios"
                              type="text"
                              holder={ registradoPreviamente ? "Escribe" : values.comentarios}
                              disabled={registradoPreviamente}
                              style={{
                                border: "none",
                                backgroundColor: "#f0f0f0",
                                resize: "none",
                              }}
                            />
                          </div>
                          </div>
                          <div className="row mb-3 px-4">
                            <div className="col-2 mt-3">
                              <p><span style={{ fontSize: "14px", fontWeight: "bold" }} >Ingreso a cartera</span></p>
                            </div>
                          </div>
                          <div className="row mb-3 px-4">
                            <div className="col-12 col-lg-3">
                              {
                                !registradoPreviamente
                                ? <SelectField
                                    id="motivo_ingreso"
                                    label="Motivo de ingreso"
                                    name="motivo_ingreso"
                                    items={dataMotivoIngreso}
                                    onChange={(event) => {
                                      setFieldValue("motivo_ingreso", event.value === "" ? "" : event.label);
                                  }}/>
                                : <TextField label="Motivo de ingreso" name="motivo_ingreso"  type="text" holder={values.motivo_ingreso}  disabled={true} />
                              }
                            </div>
                          </div>
                          <div className="mb-3 row px-4" style={{ marginTop: 30 }}>
                            <div className="col-6">
                              <TextAreaField
                                label="Comentarios de ingreso a cartera:"
                                className="col-8 mt-2"
                                rows="4"
                                name="comentario_ingreso"
                                type="text"
                                holder={ registradoPreviamente ? "Escribe" : values.comentario_ingreso}
                                disabled={registradoPreviamente}
                                style={{
                                  border: "none",
                                  backgroundColor: "#f0f0f0",
                                  resize: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className='my-2 mt-4 px-4'>
                            <div className="mb-3 row">
                              <div className="col-sm-12 col-md-6 col-lg-3">
                                <Field className="form-check-input" type="checkbox" value={values.reg_concluido} checked={values.reg_concluido} disabled={registradoPreviamente} name="reg_concluido" onClick={(event) => { 
                                  setFieldValue("reg_concluido", event.target.checked); 
                                }} />
                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                  <label>Inicio de proceso: {fechaInicioProceso}</label> | <label>Fin de proceso: {fechaFinProceso}</label>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        isOpenDocs &&
        <VisorFile
          isOpen={isOpenDocs}
          setIsOIpen={setIsOpenDocs}
          fileName={fileName}
          doc={SelectedDocs}
        />
      }
      {
        openModalSubirDocumento &&(
          <SubirDocumentoModal
            contrato_id={contratoActual.contrato_id}
            archivoId={archivoId}
            isOpen={openModalSubirDocumento}
            setIsOpen={setOpenModalSubirDocumento}
            cardId={1}
            setConfirmarOperacion={setConfirmarOperacion}
            setNombreDocumento={setNombreDocumento}
          />
        )
      }
    </>
  );
});
export default VisitaDomiciliariaCard;
