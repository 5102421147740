import React from 'react'
import Usuario from '../../Services/Users/Usuarios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cobranza from '../../Services/Cobranza/Cobranza';
import { setUsuario } from '../../Redux/Slice/Cobranza/UsuarioSeleccionadoSlice';
import { useDispatch } from 'react-redux';

const useCobradoresNivel2 = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const columns = [
      { field: 'nombrecobrador', headerName: 'Nombre', width: 210 },
      { field: 'dispobilidad', headerName: 'Disponibilidad', width: 150 },
      { field: 'fechainhabilitado', headerName: 'Fecha inhabilitado', width: 190 },
      { field: 'estatusRol', headerName: 'Estatus', width: 140 },
      { field: 'zonasasignadas', headerName: 'Zonas asignadas', width: 350 },
      { field: 'contratos', headerName: 'Contratos', width: 150 },
   ];

   const dataTest = [
      { id: 1, nombrecobrador: 'Adrian Gómez', nivel: 'Nivel 1', dispobilidad: 'Vacaciones', fechainhabilitado: '14-03-24 20-03-24', estatusRol: 'Activo', zonasasignadas: "Zona A: Aguascalientes: Aguascalientes +2", contratos: 20, status: true },
      { id: 2, nombrecobrador: 'Agustin Villanueva', nivel: 'Nivel 2', dispobilidad: '-', fechainhabilitado: 'NA', estatusRol: 'Baja', zonasasignadas: "Zona A: Aguascalientes: Aguascalientes +2", contratos: 20, status: false},
      { id: 3, nombrecobrador: 'Maximiliano Luna', nivel: 'Nivel 3', dispobilidad: 'Disponible', fechainhabilitado: 'NA', estatusRol: 'Activo', zonasasignadas: "Zona A: Aguascalientes: Aguascalientes +2", contratos: 20, status: true},
      { id: 4, nombrecobrador: 'Andrea González', nivel: 'Nivel 3 Juridico',dispobilidad: 'Disponible', fechainhabilitado: 'NA', estatusRol: 'Activo', zonasasignadas: "Zona A: Aguascalientes: Aguascalientes +2", contratos: 20, status: true},
   ]

   const dispobilidadDataTest = [
      { value: 0, label: 'Disponibilidad' },
      { value: 1, label: 'Disponible' },
      { value: 2, label: 'Vacaciones' },
      { value: 3, label: 'Otros' }
   ];

     //Se obtendran los datos de un endpoint
   const dataPersonal = [
      { value: 0, label: "Seleccionar"},
      { value: 1, label: 'Adrian Gómez'},
      { value: 2, label: 'Agustin Villanueva'},
      { value: 3, label: 'Maximiliano Luna'},
      { value: 4, label: 'Andrea González'},
   ]

   const dataNiveles = [
      { value: 0, label: "Todos los niveles"},
      { value: 1, label: "Nivel 1"},
      { value: 2, label: "Nivel 2"},
      { value: 3, label: "Nivel 3"},
      { value: 4, label: "Nivel 3 Juridico"},
    ]

    const dataEstatus = [
      { value: 0, label: "Estatus"},
      { value: 1, label: "Disponible"},
      { value: 2, label: "No disponible"}
   ]

   const dataZonas = [
      { value: 0, label: "Zonas"},
      { value: 1, label: "Zona A: Aguascalientes"},
      { value: 2, label: "Zona B: Aguascalientes"}
   ]

   // const detailsZonas= [
   //    {numero: 1, estado: "Aguascalientes", municipio: "Municipio 1", colonia: "Colonia 1"},
   //    {numero: 2, estado: "Aguascalientes", municipio: "Municipio 2", colonia: "Colonia 2"},
   //    {numero: 3, estado: "Aguascalientes", municipio: "Municipio 3", colonia: "Colonia 3"}
   // ]

   // const dataZonasUsuario = [
   //    {zonacobranza: "Zona A: Aguascalientes: Aguascalientes",constratosasignados: 10, zonaasignadatooltip: "Mauricio López, Sabrina Gonzalez", detail: detailsZonas},
   //    {zonacobranza: "Zona A: Aguascalientes: Aguascalientes",constratosasignados: 10, zonaasignadatooltip: "Mauricio López, Sabrina Gonzalez", detail: detailsZonas},
   //    {zonacobranza: "Zona A: Aguascalientes: Aguascalientes",constratosasignados: 10, zonaasignadatooltip: "Mauricio López, Sabrina Gonzalez", detail: detailsZonas}
   // ]


   const [data, setData] = useState([]);
   const [page, setPage] = useState(1);
   const [dataDisponibilidad, setDataDisponibilidad] = useState([]);
   const [idDisponibilidad,setIdDisponibilidad] = useState(0);
   const [idEstado, setIdEstado] = useState(0);
   //const [dataPersonal, setDataPersonal] = useState([]); Descomentar cuando ya se use el endpoint para traer al personal que llenara el select del modal
   const [idMunicipio ,setIdMunicipio] = useState(0);
   //const [status, setStatus] = useState("");
   const [str, setStr] = useState("");
   const [loading, setLoading] = useState(true);
   const [pageCount, setPageCount] = useState();
   //const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
   const [pageRows, setpageRows] = useState(10)
   const [isOpenModal, setIsOpenModal] = useState(false);
   const [ actualizar, setActualizar ] = useState("");
   const [nivelDefault, setNivelDefault] = useState({value: 2, label: "Nivel 2"});

   useEffect(() => {
      //handleGetAllCobradores(1, pageRows, idEstado, idMunicipio, idDisponibilidad,str);
      handleGetAllData();
      setActualizar("");
      setPage(1);
   }, [actualizar])

   useEffect(() => {
      getAllDisponibilidades();
   }, [])

   // useEffect(() =>{
   //    getMunucipios();
   // },[idEstado]);

   const handlePageClick = (event) => {
      setPage(event.selected + 1);
      //handleGetAllCobradores(event.selected + 1);
   };

   const handleGetAllData = async () => {
      let NewData = [];
      let newDataDetalles = [];
      let newDataZonas = [];
      // {zonacobranza: "Zona A: Aguascalientes: Aguascalientes",constratosasignados: 10, zonaasignadatooltip: "Mauricio López, Sabrina Gonzalez", detail: detailsZonas},
      // {numero: 1, estado: "Aguascalientes", municipio: "Municipio 1", colonia: "Colonia 1"},
      //setData(dataDetalleContratos);
      await Cobranza.getEjecutivosFullDetail()
         .then(res => {
            console.log(res);
            if(res.data.callSucceded){
               if(res.data.callSucceded){
                  res.data.responseData.map(item => {
                     if(item.nivel_cobrador === "2"){
                        let count = 1;
                        item.contratos.map(contrat =>{
                           newDataDetalles.push({numero: count, estado: contrat.estado, municipio: contrat.municipio, colonia: contrat.colonia});
                           count++;
                        })

                        item.contratos.map(contrat => {
                           newDataZonas.push()
                        })

                        NewData.push({
                           id: item.userid,
                           nombrecobrador: item.user_detail.names,
                           nivel: item.nivel_cobrador,
                           dispobilidad: item.disponible ? item.disponible : "No disponible",
                           fechainhabilitado: "0",
                           estatusRol: "Inactivo",
                           zonasasignadas: item.zona,
                           contratos: item.numContratos,
                           status: false,
                           telefono: item.numTelefono,
                           data:[
                              {zonacobranza: item.zona, constratosasignados: item.numContratos, zonaasignadatooltip: item.user_detail.names, detail: newDataDetalles}
                           ]
                        });


                     }
                  })
               }
            }
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
      setData(NewData);
      setLoading(false);
   }


   const handleGetAllCobradores = async (pag) => {
      setData(dataTest);
   //   setLoading(true);
   //   await Cobranza.getAllCobradores(pag,pageRows, idEstado, idMunicipio, idDisponibilidad,str)
   //       .then(res => {
   //           setData(res.data.data);
   //           setPageCount(res.data.totalPages);
   //           setLoading(false);
   //       }).catch(e => {
   //           setLoading(false);
   //           if (e.response !== undefined)
   //               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
   //           else
   //               setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
   //       })
   //       console.log(data);
   }

   const getAllDisponibilidades = async () => {
      setDataDisponibilidad(dispobilidadDataTest)
   //   setLoading(true);
   //   await Cobranza.getDisponibilidades()
   //       .then(res => {
   //           let NewData = [];
   //           NewData.push({ value: 0, label: 'Disponibilidad' })
   //           res.data.data?.map(item => {
   //               NewData.push({
   //                   value: item.idRole,
   //                   label: item.role
   //               })
   //           });
   //           setDataDisponibilidad(NewData);
   //           setLoading(false);
   //       }).catch(e => {
   //           setLoading(false);
   //       })
   }

   const handleDetails = (item) =>{
      dispatch(setUsuario(
         {
            userid: item.id,
            nombrecobrador: item.nombrecobrador,
            usuariosistema: "0",
            nivel: item.nivel,
            telefono: item.telefono,
            status: true,
            estatusdesistema: "Disponible",
            data: item.data
         })
      );

      navigate(`/cobranza/cobradoresdetalle`, {
         state: {
            page: 'Detalle',
            title: "Editar usuario"
         },
         });
   }

   const handleNewCobrador = async () =>{
      setIsOpenModal(true);
      //   setLoading(true);
      //   await Cobranza.getPersonal()
      //       .then(res => {
      //           let NewData = [];
      //           NewData.push({ value: 0, label: "Seleccionar"})
      //           res.data.data?.map(item => {
      //               NewData.push({
      //                   value: item.idRole,
      //                   label: item.role
      //               })
      //           });
      //           setDataPersonal(NewData);
      //           setLoading(false);
      //       }).catch(e => {
      //           setLoading(false);
      //       })

   }

   return {
      columns,
      data,
      pageCount,
      dataDisponibilidad,
      dataEstatus,
      dataZonas,
      // dataEstado,
      // dataMunicipio,
      setIdDisponibilidad,
      // setIdEstado,
      // setIdMunicipio,
      setStr,
      loading,
      handlePageClick,
      handleDetails,
      handleNewCobrador,
      page,
      setpageRows,
      isOpenModal,
      setIsOpenModal,
      dataPersonal,
      dataNiveles,
      setActualizar,
      nivelDefault
   }
}

export default useCobradoresNivel2