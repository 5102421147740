import { Formik, Form} from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import { TextField } from '../../../../Components/TextField';
import Spinner from '../../../../Components/Loadig';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';
import MultiSelect from '../../../../Components/DropDown';
import { SelectField } from '../../../../Components/SelectField';
import { useNavigate } from 'react-router-dom';
import { setDatosRuta } from '../../../../Redux/Slice/Cobranza/SeguimientoVisita/DetalleRutaSlice';
import { useDispatch } from 'react-redux';

const CrearRutaModal = ({ isOpen, setIsOpen, tipoUsuario}) => {
   const refbtn = useRef();
   const [fechaInicio, setFechaInicio] = useState("01/01/1970");
   const [fechaFin, setFechaFin] = useState("01/01/1970");
   const [fechaValida, setFechaValida] = useState(false);
   const [dataPersonal, setDataPersonal] = useState([]);
   const [state, setState] = useState([]);
   const [rangofechas, setRangoFechas] = useState(false);
   const [dataZonas, setDataZonas] = useState([])
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [contratos, setContratos] = useState(5);
   const userId = sessionStorage.getItem('userId').trim()

   let initial = {
      folio: "Por definir al guardar",
      nombrecobrador: "",
      zona: []
   }

   const [initialValue, setInitialValue] = useState(initial);
   const [loading, setLoading] = useState(false);

   const validate = Yup.object({
      nombrecobrador: Yup.string()
         .required("El campo es requerido."),
   });

   useEffect(()=>{
      handleGetAllUsersNotInCobranza();
      handleGetAllZonas();
   },[]);

   const handleGetAllUsersNotInCobranza = async () => {
      let NewData = [];
      await CobranzaService.getAllEjecutivosWithFullDetail()
         .then(res => {
            console.log(res.data.responseData)
            console.log(res)
            if(res.data.callSucceded){
               res.data.responseData.map(item => {
                  NewData.push({value: item.userId, label: item.user_detail.names})
               })
            }
         })
         .catch(e => {
            console.log(`ERROR -- ${e}`)
         })
      setDataPersonal(NewData);
   }

   const handleGetAllZonas = async () => {
      let NewData = [];
      await CobranzaService.getAllEjecutivosWithFullDetail()
         .then(res => {
            if(res.data.callSucceded){
               if(tipoUsuario === 1){
                  res.data.responseData?.map(item => {
                     item.contratos?.map(element => {
                        NewData.push({
                           value: element.zonaid,
                           label: element.zona
                        })
                     })
                  })
               }
               else{
                  res.data.responseData.map(item => {
                     item.contratos.map(element => {
                        if(item.userid.toLowerCase() === userId.toLowerCase()){
                           NewData.push({
                              value: element.zonaid,
                              label: element.zona
                           })
                        }
                     })
                  })
               }
            }
         })
      setDataZonas(NewData);
   }

   const handleAdd = async (values) => {
      dispatch(setDatosRuta({
         ruta: values.folio,
         nombrecobrador: values.nombrecobrador,
         fechainicio: fechaInicio,
         fechafin: fechaFin,
         contratos: contratos,
         items: []
         })
      );

      navigate("/cobranza/seguimiento-visita/rutas-cobranza/ruta-detalle", {
         state: {
            page: 'Detalle',
            title: "Detalle de ruta",
            puedeEditar: true,
            tipoUsuario: tipoUsuario
         },
      });
      setIsOpen(false);
   }

   const handleValidarFechas = (tipoFecha, fecha, validarFechas) => {
      if (validarFechas) {
         setFechaValida(false);
         if(tipoFecha === 1){
            let validarFecha = fechaFin > fecha;
            if(validarFecha && fecha !== "" && fecha != null){
               setFechaInicio(fecha);
               setFechaValida(true);
            }
            else{
               setFechaInicio(fecha);
               setFechaValida(false);
            }
         }
         if(tipoFecha === 2){
            let validarFecha = fecha > fechaInicio;
            if(validarFecha && fechaInicio !== "01/01/1970"){
               setFechaFin(fecha);
               setFechaValida(true);
            }
            else{
               setFechaFin(fecha);
               setFechaValida(false);
            }
         }
      } else {
         setFechaValida(true);
         setFechaFin(fechaInicio);
      }
   }

   const handleChange = value => {
      setState(value);
   }

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         validationSchema={validate}
         onSubmit={(values) => {
               handleAdd(values);
         }}
      >{
         ({ isValid, values, setFieldValue }) => (
            <>
               <Modal isOpen={isOpen} color='#fff'>
                  <section className='my-2 mx-4' style={{ width: '40rem' }}>
                     <Form>
                        <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Crear nueva ruta </strong></span>
                        <p className='mt-3'><span style={{color: "#000", fontWeight: "bold"}}>1. Datos de ruta</span></p>
                        <div className="mb-3 row" style={{marginTop:30}}>
                              <div className="col-sm-6">
                                 <TextField label="Folio" name="folio" type="text" holder={values.folio} disabled={true} />
                              </div>
                              {
                                 tipoUsuario === 1 &&(
                                    <div className="col-sm-6">
                                       <SelectField  label="Cobrador" name="nombrecobrador" items={dataPersonal} onChange={(e) => {
                                          setFieldValue("nombrecobrador", e.label !== 'Seleccione' ? e.label : "");
                                       }} />
                                    </div>
                                 )
                              }
                        </div>
                        <div className="mb-3 row" style={{marginTop:30}}>
                           <label className='col-6 mx-1'>
                              Zona
                              <MultiSelect
                                 label="zona"
                                 holder={"Seleccione"}
                                 className={'col-12 mx-1'}
                                 value={state}
                                 options={dataZonas}
                                 handleChange={(e)=> {
                                    handleChange(e);
                                    setFieldValue("zona", e.value !== 0 ? e.value : []);
                                 }
                              }
                              />
                           </label>
                           </div>
                        <div className="mb-3 row" style={{marginTop:30}}>
                           <div className="col-sm-6">
                              Fecha inicio
                              <input type="date" className="form-control mb-4" placeholder="Fecha inicio" onChange={(e) => { setFechaInicio(e.target.value);  handleValidarFechas(1, e.target.value, rangofechas); }} />
                           </div>
                           { rangofechas && (
                              <div className="col-sm-6">
                                 Fecha fin
                                 <input type="date" className="form-control mb-4" placeholder="Fecha fin" onChange={(e) => { setFechaFin(e.target.value); handleValidarFechas(2, e.target.value, rangofechas); }} />
                              </div>
                           )}
                        </div>
                        <div className="mb-3 row" style={{marginTop:30}}>
                           <div className="form-check mx-1 col-sm-6">
                              <span><input className="form-check-input" type="checkbox" checked={rangofechas} value={"rangodefechas"} onChange={(e) => { setRangoFechas(e.target.checked); handleValidarFechas(2, fechaFin, e.target.checked); }} /> Rango de fechas</span>
                           </div>
                        </div>
                        <section className='d-flex justify-content-end mt-5'>
                           <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                           <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' ref={refbtn} type="submit" disabled={!(isValid)|| !(fechaValida) || state.length === 0}>Guardar</button>
                        </section>
                     </Form>
                  </section>
               </Modal>
               {
                  loading &&
                  <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
               }
            </>
         )
      }
   </Formik>
   )
}

export default CrearRutaModal