import React from 'react'
import Usuario from '../../Services/Users/Usuarios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cobranza from '../../Services/Cobranza/Cobranza';

const useCobradoresNivel1 = () => {
   const navigate = useNavigate();

   const columns = [
      { field: 'nombrecobrador', headerName: 'Nombre', width: 210 },
      { field: 'dispobilidad', headerName: 'Disponibilidad', width: 150 },
      { field: 'fechainhabilitado', headerName: 'Fecha inhabilitado', width: 190 },
      { field: 'estatusdesistema', headerName: 'Estatus de sistema', width: 210 },
      { field: 'contratos', headerName: 'Contratos', width: 150 },
      { field: 'historialdesistema', headerName: 'Historial Estatus de sistema', width: 250 },
      { field: 'estatulaboral', headerName: 'Estatus laboral', width: 200 },
   ];

   const dataTest = [
      { id: 1, nombrecobrador: 'Adrian Gómez', nivel: 'Nivel 1', dispobilidad: 'Vacaciones', fechainhabilitado: '14-03-24 20-03-24', estatusdesistema: 'Dispobible', contratos: 20, historialdesistema: true, estatulaboral: 'Activo', statussistem: true, statuslab: true, telefono: "123456789", usuariosistema: "Adrian.gomez" },
      { id: 2, nombrecobrador: 'Agustin Villanueva', nivel: 'Nivel 2', dispobilidad: '-', fechainhabilitado: 'NA', estatusdesistema: 'No disponible', contratos: 20, historialdesistema: true, estatulaboral: 'Baja', statussistem: false, statuslab: false, telefono: "123456789", usuariosistema: "Adrian.gomez"},
      { id: 2, nombrecobrador: 'Andrea González', nivel: 'Nivel 3', dispobilidad: 'Disponible', fechainhabilitado: 'NA', estatusdesistema: 'Disponible', contratos: 20, historialdesistema: true, estatulaboral: 'Baja', statussistem: true, statuslab: false, telefono: "123456789", usuariosistema: "Adrian.gomez" },
      { id: 2, nombrecobrador: 'Maximiliano Luna', nivel: 'Nivel 3 Juridico', dispobilidad: 'Disponible', fechainhabilitado: 'NA', estatusdesistema: 'Disponible', contratos: 20, historialdesistema: true, estatulaboral: 'Activo', statussistem: true, statuslab: true, telefono: "123456789", usuariosistema: "Adrian.gomez" },
]

   const dispobilidadDataTest = [
      { value: 0, label: 'Disponibilidad' },
      { value: 1, label: 'Disponible' },
      { value: 2, label: 'Vacaciones' },
      { value: 3, label: 'Otros' }
   ];

     //Se obtendran los datos de un endpoint
   const dataPersonal = [
      { value: 0, label: "Seleccionar"},
      { value: 1, label: 'Adrian Gómez'},
      { value: 2, label: 'Agustin Villanueva'},
      { value: 3, label: 'Maximiliano Luna'},
      { value: 4, label: 'Andrea González'},
   ]

   const dataNiveles = [
      { value: 0, label: "Todos los niveles"},
      { value: "1", label: "Nivel 1"},
      { value: "2", label: "Nivel 2"},
      { value: "3", label: "Nivel 3"},
      { value: "4", label: "Nivel 4"},
   ]

   const dataEstatus = [
      { value: 0, label: "Estatus"},
      { value: 1, label: "Disponible"},
      { value: 2, label: "No disponible"}
   ]

   const dataEstadoLaboral = [
      { value: 0, label: "Estado laboral"},
      { value: 1, label: "Activo"},
      { value: 2, label: "Bja"}
   ]

   const [data, setData] = useState([]);
   const [page, setPage] = useState(1);
   const [dataDisponibilidad, setDataDisponibilidad] = useState(dispobilidadDataTest);
   const [idDisponibilidad,setIdDisponibilidad] = useState(0);
   const [str, setStr] = useState("");
   const [loading, setLoading] = useState(true);
   const [pageCount, setPageCount] = useState();
   const [pageRows, setpageRows] = useState(10)
   const [isOpenModal, setIsOpenModal] = useState(false);
   const [OpenModalEditar, setIsOpenModalEditar] = useState(false);
   const [item, setItem] = useState(null);
   const [ openModalHistorial, setOpenModalHistorial] = useState(false);
   const [ actualizar, setActualizar ] = useState("");
   const [nivelDefault, setNivelDefault] = useState({value: 1, label: "Nivel 1"});

   useEffect(() => {
      // handleGetAllCobradores(1, pageRows, idEstado, idMunicipio, idDisponibilidad,str);
      handleGetAllData();
      setActualizar("");
      setPage(1);
   }, [actualizar])

   // useEffect(() =>{
   //    getMunucipios();
   // },[idEstado]);

   const handlePageClick = (event) => {
      setPage(event.selected + 1);
      // handleGetAllCobradores(event.selected + 1);
   };

   const handleGetAllData = async () => {
      let NewData = [];
      await Cobranza.getEjecutivosFullDetail()
         .then(res => {
            if(res.data.callSucceded){
               res.data.responseData.map(item => {
                  if(item.nivel_cobrador === "1"){
                     NewData.push({
                        id: item.userid,
                        nombrecobrador: item.user_detail.names,
                        nivel: item.nivel_cobrador,
                        dispobilidad: item.disponible != null ? item.disponible : "No disponible",
                        fechainhabilitado: "0",
                        estatusdesistema: "No disponible",
                        contratos: item.numContratos,
                        historialdesistema: true,
                        estatulaboral: 'Inactivo',
                        statussistem: false,
                        statuslab: false,
                        telefono: item.numTelefono,
                        usuariosistema: "0",
                        diasinhabiles: item.diasInhabiles
                     })
                  }
               })
            }
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
         setData(NewData);
         setLoading(false);
      }

   // const getAllEstados = async () => {
   //    setDataEstado(estadosDataTest);
   //    //   setLoading(true);
   //    //   await Cobranza.getEstados()
   //    //       .then(res => {
   //    //           let NewData = [];
   //    //           NewData.push({ value: 0, label: 'Estado' })
   //    //           res.data.data?.map(item => {
   //    //               NewData.push({
   //    //                   value: item.idRole,
   //    //                   label: item.role
   //    //               })
   //    //           });
   //    //           setDataEstado(NewData);
   //    //           setLoading(false);
   //    //       }).catch(e => {
   //    //           setLoading(false);
   //    //       })
   //    }

      // const handleGetMunucipios = async (value) => {
      //    setIdEstado(value);
      //    setDefaulValudeModelo({ value: 0, label: 'Municipio' })

      //    if(value == 1 || value == 3){
      //       setDataMunicipio(municipiosDataTest);
      //    }
      //    else if(value == 2)
      //    {
      //       setIdMunicipio(0);
      //       setDataMunicipio(municipiosDataTest2);
      //    }
      //    else
      //    {
      //       setIdMunicipio(0);
      //       setDataMunicipio([{ value: 0, label: 'Municipio' }]);
      //    }
      //    //   setLoading(true);
      //    //   await Cobranza.getMunicipios(idEstado)
      //    //       .then(res => {
      //    //           let NewData = [];
      //    //           NewData.push({
      //    //               value: "",
      //    //               label: "Todos los Roles"
      //    //           })
      //    //           res.data.data?.map(item => {
      //    //               NewData.push({
      //    //                   value: item.idRole,
      //    //                   label: item.role
      //    //               })
      //    //           });
      //    //           setDataRol(NewData);
      //    //           setLoading(false);
      //    //       }).catch(e => {
      //    //           setLoading(false);
      //    //       })
      //    }
   
   // const handleChangeMunicipio = async (item) =>{
   //    setDefaulValudeModelo(item);
   //    setIdMunicipio(item.value);
   // }

   const handleNewCobrador = async () =>{
      setIsOpenModal(true);
   }

   const handleDetails = (item) => {
      setItem(item);
      setIsOpenModalEditar(true);
   }

   const handleHistorial = (item) => {
      console.log(item);
      setItem(item);
      setOpenModalHistorial(true);
   }

   return {
      columns,
      data,
      pageCount,
      dataDisponibilidad,
      dataEstatus,
      dataEstadoLaboral,
      setIdDisponibilidad,
      setStr,
      loading,
      handlePageClick,
      handleDetails,
      handleNewCobrador,
      page,
      setpageRows,
      isOpenModal,
      setIsOpenModal,
      // defaultValueMunicipio,
      // handleGetMunucipios,
      // handleChangeMunicipio,
      dataPersonal,
      dataNiveles,
      OpenModalEditar, 
      setIsOpenModalEditar,
      item,
      handleHistorial,
      openModalHistorial,
      setOpenModalHistorial,
      setActualizar,
      nivelDefault
   }
}

export default useCobradoresNivel1