import React, { useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Accordion from "../../../../Components/TitleAcordeon"
import Spinner from "../../../../Components/Loadig"
import Modal from "../../../../Components/Modal/Modal"
import { TextField } from '../../../../Components/TextField';
import { Formik, Form, Field } from 'formik'
import { TextAreaField } from '../../../../Components/TextAreaField';
import { SelectField } from '../../../../Components/SelectField';
import { toast, Toaster } from 'react-hot-toast';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';
import { message } from '../../../../Utils/functions';

const RegistroPromesaCards = React.forwardRef(({ ...props }, ref) => {
   const {itemGeneral} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [collpsableGrua, setCollpsableGrua] = useState(false);
   const refFormik = useRef();
   const { state } = useLocation();
   const [show, setShow] = useState(props.show);
   // const {
   //    loading
   // } = useHistorialVisitasDetalle(state);
   useEffect(() => {
      setShow(props.show);
   }, [props.show]);

   let initialGarantiaPago = {
      tipoPromesa: "Garantía de pago",
      folio: "",
      proceso: "",
      cobradorApoyo: "",
      cobradores: "",
      grua: "",
      fechaVencimiento: "",
      diasPromesa: "",
      montoVencido: "",
      pagoPeriodo: "",
      total: "",
      registroConcluido: false,
      fechaInicioProceso: "23/10/2023 09:00",
      fechaFinProceso: "23/10/2023 09:00"
   };
   let initialPromesaPago = {
      tipoPromesa: "Promesa de pago",
      motivoPromesa: "",
      fechaCompromiso: "",
      horarioCompromiso: "",
      montoPrometido: "",
      montoVencido: "",
      comentariosPromesaPago: "",
      registroConcluido: false,
      fechaInicioProceso: "23/10/2023 09:00",
      fechaFinProceso: "23/10/2023 09:00"
   };
   let initialPromesaEntregaDefinitiva = {
      tipoPromesa: "Promesa de entrega definitiva",
      folio: "",
      proceso: "",
      cobradorApoyo: "",
      cobradores: "",
      grua: "",
      registroConcluido: false,
      fechaInicioProceso: "23/10/2023 09:00",
      fechaFinProceso: "23/10/2023 09:00"
   };
   const [datosGarantiaPago, setDatosGarantiaPago] = useState(initialGarantiaPago);
   const [datosPromesaPago, setDatosPromesaPago] = useState(initialPromesaPago);
   const [datosPromesaEntregaDefinitiva, setDatosPromesaEntregaDefinitiva] = useState(initialPromesaEntregaDefinitiva);
   const [cobradorApoyoSeleccionado, setCobradorApoyoSeleccionado] = useState(0);
   const [cobradorSeleccionado, setCobradorSeleccionado] = useState(0);
   const [gruaSeleccionado, setGruaSeleccionado] = useState(0);
   const [motivoPromesaSeleccionada, setMotivoPromesaSeleccionada] = useState(0);
   const [loading, setLoading] = useState(false);

   const dataCobradorApoyo = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" }
   ];

   const dataCobrador = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" }
   ];

   const dataGrua = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" }
   ];

   const dataMotivoPromesa = [
      { value: 1, label: "Motivo 1" },
      { value: 2, label: "Motivo 2" },
      { value: 3, label: "Motivo 3" },
      { value: 4, label: "Motivo 4" }
   ];

   const [formularioActual, establecerFormularioActual] = useState(0);
   const handleNextForm = (e) => {
      e.preventDefault();
      establecerFormularioActual((formularioPrevio) => (formularioPrevio + 1) % 3);
   };

   const handleGuardar = async (values) => {
      setLoading(true);
      let data = {};
      let idRegistroPrincipal = crypto.randomUUID();
      if (formularioActual == 0) {
         {/* tipo promesa: garantia de pago */ }
         data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "flag": false,
            "anyObject": {
               "id": idRegistroPrincipal,
               "ruta_head_id": itemGeneral.ruta_head_id,
               "ruta_item_id": "",
               "evento_head_id": itemGeneral.evento_head_id,
               "evento_item_id": itemGeneral.evento_item_id,
               "tipo_promesa": "",
               "folio": null,
               "proceso": values.proceso,
               "cobrador_apoyo": 1,
               "cobradores": values.cobradores,
               "grua": 1,
               "motivo_promesa": "",
               "fecha_compromiso": "",
               "fecha_vencimiento": "",
               "dias_de_promesa": 5,
               "monto_prometido": 0,
               "monto_vencido": 0,
               "comentarios": "",
               "pago_periodo": values.pagoPeriodo,
               "monto_por_cubrir": 0,
               "registro_concluido": 1
            }
         }
      }
      if (formularioActual == 1) {
         {/* tipo promesa: promesa de pago */ }
         data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "flag": false,
            "anyObject": {
               "id": idRegistroPrincipal,
               "ruta_head_id": itemGeneral.ruta_head_id,
               "ruta_item_id": "",
               "evento_head_id": itemGeneral.evento_head_id,
               "evento_item_id": itemGeneral.evento_item_id,
               "tipo_promesa": "",
               "folio": null,
               "proceso": values.proceso,
               "cobrador_apoyo": 1,
               "cobradores": values.cobradores,
               "grua": 1,
               "motivo_promesa": "",
               "fecha_compromiso": "",
               "fecha_vencimiento": "",
               "dias_de_promesa": 5,
               "monto_prometido": 0,
               "monto_vencido": 0,
               "comentarios": "",
               "pago_periodo": values.pagoPeriodo,
               "monto_por_cubrir": 0,
               "registro_concluido": 1
            }
         }
      }
      if (formularioActual == 2) {
         {/* tipo promesa: promesa de entrega definitiva */ }
         data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "flag": false,
            "anyObject": {
               "id": idRegistroPrincipal,
               "ruta_head_id": itemGeneral.ruta_head_id,
               "ruta_item_id": "",
               "evento_head_id": itemGeneral.evento_head_id,
               "evento_item_id": itemGeneral.evento_item_id,
               "tipo_promesa": "",
               "folio": null,
               "proceso": values.proceso,
               "cobrador_apoyo": 1,
               "cobradores": values.cobradores,
               "grua": 1,
               "motivo_promesa": "",
               "fecha_compromiso": "",
               "fecha_vencimiento": "",
               "dias_de_promesa": 5,
               "monto_prometido": 0,
               "monto_vencido": 0,
               "comentarios": "",
               "pago_periodo": values.pagoPeriodo,
               "monto_por_cubrir": 0,
               "registro_concluido": 1
            }
         }
      }

      await CobranzaService.createUpsertCobranzaPromesaPago(data)
         .then(res => {
            props.handleNextSteps(formularioActual);
            setLoading(false)
            toast.success(message("¡Operación exitosa!", res.data.mensaje));
         }).catch(e => {
            console.log(e)
            setLoading(false)
            props.handleNextSteps(false);
            if (e.response !== undefined)
               toast.error(message("¡Error!", e.response.data.message));
            else
               toast.error(message("¡Error!", e.message));
         });
   }

   //VALIDACIONES DE FORMULARIOS
   {/* tipo promesa: garantia de pago */ }
   const validateFormGarantiaPago = Yup.object({
      tipoPromesa: Yup.string().required("El campo es requerido."),
      folio: Yup.string().required("El campo es requerido."),
      proceso: Yup.string().required("El campo es requerido."),
      cobradorApoyo: Yup.string().required("El campo es requerido."),
      cobradores: Yup.string().required("El campo es requerido."),
      grua: Yup.string().required("El campo es requerido."),
      fechaVencimiento: Yup.string().required("El campo es requerido."),
      diasPromesa: Yup.string().required("El campo es requerido."),
      montoVencido: Yup.string().required("El campo es requerido."),
      pagoPeriodo: Yup.string().required("El campo es requerido."),
      total: Yup.string().required("El campo es requerido."),
   });
   {/* tipo promesa: promesa de pago */ }
   const validateFormPromesaPago = Yup.object({
      tipoPromesa: Yup.string().required("El campo es requerido."),
      motivoPromesa: Yup.string().required("El campo es requerido."),
      fechaCompromiso: Yup.string().required("El campo es requerido."),
      horarioCompromiso: Yup.string().required("El campo es requerido."),
      montoPrometido: Yup.string().required("El campo es requerido."),
      montoVencido: Yup.string().required("El campo es requerido."),
      comentariosPromesaPago: Yup.string().required("El campo es requerido."),
   });
   {/* tipo promesa: promesa de entrega definitiva */ }
   const validateFormPromesaEntregaDefinitica = Yup.object({
      tipoPromesa: Yup.string().required("El campo es requerido."),
      folio: Yup.string().required("El campo es requerido."),
      proceso: Yup.string().required("El campo es requerido."),
      cobradorApoyo: Yup.string().required("El campo es requerido."),
      cobradores: Yup.string().required("El campo es requerido."),
      grua: Yup.string().required("El campo es requerido."),
   });

   return (
      <>
         
         <Toaster
            position="top-right"
            toastOptions={{
               success: {
                  style: {
                     background: '#47a066',
                     color: '#FFFF',
                     borderLeft: '10px solid #2f7246'
                  },
               },
               error: {
                  style: {
                     background: '#d53f3f',
                     color: '#FFFF',
                     borderLeft: '10px solid #ac241a'
                  },
               },
            }}
         />
         {
            loading &&
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         }
         { show && (
         <div className="row wrapper-vehiculo">
            <div className="col col-12">
               <div className="row mx-md-4 my-4">
                  <div className="col-12 px-md-3">
                     <div className="p-4 white-wrapper mb-2">
                        <div
                           className="row mt-2 px-4"
                           data-bs-toggle="collapse"
                           href="#collapsePromesaPago"
                           role="button"
                           aria-expanded="false"
                           aria-controls="collapseRegistroTramite"
                           onClick={() => setCollpsableGrua(!collpsableGrua)}
                        >
                           <div className="col-11">
                              <h6>
                                 <span className={`semaforo semaforo-`} ></span>{" "}
                                 <strong>Promesa de Pago</strong>
                              </h6>
                           </div>
                           <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                              <i className={ !collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2" }></i>
                           </div>
                        </div>
                        <div className="collapse" id="collapsePromesaPago">
                           <div className="separator"></div>
                           <div className="row mt-2 px-4">
                              {/* registro de promesa */}
                              {/* tipo promesa: garantia de pago */}
                              {formularioActual === 0 && (
                                 <Formik innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={datosGarantiaPago}
                                    validationSchema={validateFormGarantiaPago}
                                 >
                                    {({ isValid, values, setFieldValue }) => (
                                       <Form>
                                          <div className="row">
                                             <div className="col-sm-12 col-md-3">
                                                <p className="text-base"><strong>Detalle de promesa de pago</strong>
                                                </p>
                                             </div>
                                             <div className="col-sm-12 col-md-3">
                                                <a href="#" onClick={(e) => { handleNextForm(e) }}>Da click aquí para ver las variaciones*</a>
                                             </div>
                                             <div className="col-sm-12 col-md-6 text-end">
                                                <button className='btn btn-gray'>
                                                   <i className="icon-light fa fa-file "></i> Subir documento
                                                </button>
                                                <button id="AT_btnGuardar" className='btn mx-2' disabled={!values.registroConcluido} onClick={() => handleGuardar(values)}>
                                                   Guardar
                                                </button>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Tipo de promesa" disabled name="tipoPromesa" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Folio" name="folio" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Proceso" name="proceso" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="cobradorApoyo" label="Cobrador de apoyo" name="cobradorApoyo" items={dataCobradorApoyo}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("cobradorApoyo", "");
                                                            setCobradorApoyoSeleccionado(0);
                                                         }
                                                         else {
                                                            setCobradorApoyoSeleccionado(event.value);
                                                            setFieldValue("cobradorApoyo", event.label);
                                                         }
                                                      }} />
                                                </div>
                                             </div>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="cobradores" label="Cobradores (en caso de aplicar)" name="cobradores" items={dataCobrador}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("cobradores", "");
                                                            setCobradorSeleccionado(0);
                                                         }
                                                         else {
                                                            setCobradorSeleccionado(event.value);
                                                            setFieldValue("cobradores", event.label);
                                                         }
                                                      }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="grua" label="Grua" name="grua" items={dataGrua}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("grua", "");
                                                            setGruaSeleccionado(0);
                                                         }
                                                         else {
                                                            setGruaSeleccionado(event.value);
                                                            setFieldValue("grua", event.label);
                                                         }
                                                      }} />
                                                </div>
                                             </div>
                                          </div>
                                          <p className="text-base"><strong>Fecha de promesa</strong></p>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   {/* <TextField label="Fecha de vencimiento" name="fechaVencimiento" type="text" holder="" /> */}
                                                   <TextField id="fechaVencimiento" label="Fecha de vencimiento" name="fechaVencimiento" type="date" holder="Escribe" onChange={(event) => { setFieldValue("fechaVencimiento", event.target.value); }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Días de promesa" name="diasPromesa" type="text" holder="" />
                                                </div>
                                             </div>
                                          </div>
                                          <p className="text-base"><strong>Monto por cubrir</strong></p>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-center">
                                                   <TextField label="Monto vencido" name="montoVencido" type="text" holder="" />
                                                   <div className="mt-3 ms-3">
                                                      <i className="icon-light fa fa-plus ms-2" style={{ color: '#1351A5' }}></i>
                                                   </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-center">
                                                   <TextField label="Pago de periodo" name="pagoPeriodo" type="text" holder="" />
                                                   <div className="mt-3 ms-3">
                                                      <i className="icon-light fa fa-equals ms-2" style={{ color: '#1351A5' }}></i>
                                                   </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Total" name="total" type="text" holder="" />
                                                </div>
                                             </div>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <Field className="form-check-input" type="checkbox" name="registroConcluido" onClick={(event) => { setFieldValue("concluido", event.target.value); }} />
                                                   <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                   <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                                </div>
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik >
                              )}
                              {/* tipo promesa: promesa de pago */}
                              {formularioActual === 1 && (
                                 <Formik innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={datosPromesaPago}
                                 validationSchema={validateFormPromesaPago}
                                 >
                                    {({ isValid, values, setFieldValue }) => (
                                       <Form>
                                          <div className="row">
                                             <div className="col-sm-12 col-md-3">
                                                <p className="text-base"><strong>Detalle de promesa de pago</strong>
                                                </p>
                                             </div>
                                             <div className="col-sm-12 col-md-3">
                                                <a href="#" onClick={(e) => { handleNextForm(e) }}>Da click aquí para ver las variaciones*</a>
                                             </div>
                                             <div className="col-sm-12 col-md-6 text-end">
                                                <button className='btn btn-gray'>
                                                   <i className="icon-light fa fa-file "></i> Subir documento
                                                </button>
                                                <button id="AT_btnGuardar" className='btn mx-2' disabled={!values.registroConcluido} onClick={() => handleGuardar(values)}>
                                                   Guardar
                                                </button>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField disabled label="Tipo de promesa" name="tipoPromesa" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   {/* <TextField disabled label="Motivo promesa" name="motivoPromesa" type="text" holder="" /> */}
                                                   <SelectField id="motivoPromesa" label="Motivo promesa" name="motivoPromesa" items={dataMotivoPromesa}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("motivoPromesa", "");
                                                            setMotivoPromesaSeleccionada(0);
                                                         }
                                                         else {
                                                            setMotivoPromesaSeleccionada(event.value);
                                                            setFieldValue("motivoPromesa", event.label);
                                                         }
                                                      }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   {/* <TextField label="Fecha compromiso" name="fechaCompromiso" type="text" holder="" /> */}
                                                   <TextField id="fechaCompromiso" label="Fecha compromiso" name="fechaCompromiso" type="date" holder="Escribe" onChange={(event) => { setFieldValue("fechaCompromiso", event.target.value); }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   {/* <TextField label="Horario compromiso" name="horarioCompromiso" type="text" holder="" /> */}
                                                   <TextField id="horarioCompromiso" label="Horario compromiso" name="horarioCompromiso" type="time" holder="Escribe" onChange={(event) => { setFieldValue("horarioCompromiso", event.target.value); }} />
                                                </div>
                                             </div>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Monto prometido" name="montoPrometido" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Monto vencido" name="montoVencido" type="text" holder="" />
                                                </div>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                   <TextAreaField label="Comentarios promesa de pago" className="form-control col-12" rows="4" name="comentariosPromesaPago" type="text" holder="" />
                                                </div>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <Field className="form-check-input" type="checkbox" name="registroConcluido" onClick={(event) => { setFieldValue("concluido", event.target.value); }} />
                                                   <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                   <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                                </div>
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik >
                              )}
                              {/* tipo promesa: promesa de entrega definitiva */}
                              {formularioActual === 2 && (
                                 <Formik innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={datosPromesaEntregaDefinitiva}
                                 validationSchema={validateFormPromesaEntregaDefinitica}
                                 >
                                    {({ isValid, values, setFieldValue }) => (
                                       <Form>
                                          <div className="row">
                                             <div className="col-sm-12 col-md-3">
                                                <p className="text-base"><strong>Detalle de promesa de pago</strong>
                                                </p>
                                             </div>
                                             <div className="col-sm-12 col-md-3">
                                                <a href="#" onClick={(e) => { handleNextForm(e) }}>Da click aquí para ver las variaciones*</a>
                                             </div>
                                             <div className="col-sm-12 col-md-6 text-end">
                                                <button className='btn btn-gray'>
                                                   <i className="icon-light fa fa-file "></i> Subir documento
                                                </button>
                                                <button id="AT_btnGuardar" className='btn mx-2' disabled={!values.registroConcluido} onClick={() => handleGuardar(values)}>
                                                   Guardar
                                                </button>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField disabled label="Tipo de promesa" name="tipoPromesa" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Folio" name="folio" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Proceso" name="proceso" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="cobradorApoyo" label="Cobrador de apoyo" name="cobradorApoyo" items={dataCobradorApoyo}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("cobradorApoyo", "");
                                                            setCobradorApoyoSeleccionado(0);
                                                         }
                                                         else {
                                                            setCobradorApoyoSeleccionado(event.value);
                                                            setFieldValue("cobradorApoyo", event.label);
                                                         }
                                                      }} />
                                                </div>
                                             </div>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="cobradores" label="Cobradores (en caso de aplicar)" name="cobradores" items={dataCobrador}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("cobradores", "");
                                                            setCobradorSeleccionado(0);
                                                         }
                                                         else {
                                                            setCobradorSeleccionado(event.value);
                                                            setFieldValue("cobradores", event.label);
                                                         }
                                                      }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="grua" label="Grua" name="grua" items={dataGrua}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("grua", "");
                                                            setGruaSeleccionado(0);
                                                         }
                                                         else {
                                                            setGruaSeleccionado(event.value);
                                                            setFieldValue("grua", event.label);
                                                         }
                                                      }} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <Field className="form-check-input" type="checkbox" name="registroConcluido" onClick={(event) => { setFieldValue("concluido", event.target.value); }} />
                                                   <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                   <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                                </div>
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik >
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         )}
      </>
   )
})

export default RegistroPromesaCards